import React from 'react';

import MonacoBambino from '../Images/IlBambinoEdIlMonaco/EtzyFullFrame.jpg';
import InTheRain from '../Images/InTheRain/InTheRainFullFrame.jpg';
import Piccione from '../Images/LaDonnaPiccione/laDonnaPiccione.jpeg';



export const STORE_DATA = [
    {
        id: 1,
        name: 'In the rain',
        description: 'Camminando sotto la pioggia',
        image: InTheRain,
        etsy: 'https://www.etsy.com/it/listing/901867505/camminando-sotto-la-pioggia-fotografia'
    },
    {
        id: 2, name: 'Monch and the baby',
        description: 'Birmania tempio buddista',
        image: MonacoBambino,
        etsy: 'https://www.etsy.com/it/listing/900661989/il-monaco-ed-il-bambino-the-monk-and-the'
    },
    {
        id: 3,
        name: 'La donna piccione',
        description: 'Passeggiando per le strade di un paese ex comunista',
        image: Piccione,
        etsy: 'https://www.etsy.com/it/listing/930711228/the-pigeon-woman-la-donna-piccione'
    },

];